import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from './rest.service';
import { TokenService } from './token.service';

declare const DeviceUUID: any;

/* *
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly _http = inject(RestService);
  private readonly tokenService = inject(TokenService);

  // Peticion de subscripcion push
  public subscribePushNotification(subscription: any = {}): Observable<any> {
    if (!this.tokenService.get().isValid()) {
      return of({});
    }

    let uuid = null;
    if (typeof DeviceUUID !== 'undefined') {
      uuid = new DeviceUUID().get();
    }

    return this._http
      .post(`/v1/notification/subscribe`, { subscription, device: uuid })
      .pipe(map((res) => res?.body?.data));
  }
}
