import { environment } from '@/environments/environment';
import { MeEntity } from '@admefy/domain';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { createCustomElement } from '@angular/elements';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { TranslocoService, getBrowserLang } from '@jsverse/transloco';
import { WA_WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressRouter } from 'ngx-progressbar/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { GlobalStateService } from './core/services/globalstate.service';
import { LocalStorage } from './core/services/local-storage.service';
import { LoginService } from './core/services/login.service';
import { NotificationService } from './core/services/notification.service';
import { Socket } from './core/services/socket';
import { SpinnerService } from './core/services/spinner';
import { TokenService } from './core/services/token.service';
import { UserService } from './core/services/user.service';
import Sound from './core/utils/sound';

@UntilDestroy()
@Component({
  selector: 'admefy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgProgressbar, NgProgressRouter],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly document = inject(DOCUMENT);
  private readonly window = inject(WA_WINDOW);
  private readonly renderer = inject(Renderer2);
  private readonly injector = inject(Injector);
  private readonly swPush = inject(SwPush);
  private readonly swUpdate = inject(SwUpdate);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly localStorage = inject(LocalStorage);
  private readonly translate = inject(TranslocoService);
  private readonly socket = inject(Socket);
  private readonly userService = inject(UserService);
  private readonly notificationService = inject(NotificationService);
  private readonly spinnerService = inject(SpinnerService);
  private readonly gs = inject(GlobalStateService);
  private readonly loginService = inject(LoginService);
  private readonly tokenService = inject(TokenService);
  private readonly toastr = inject(ToastrService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  currentUser: MeEntity;
  intervalId: any = null;
  private isLogin = false;
  deferredPrompt: any;
  isAlive = true;

  protected afterViewInit$ = new BehaviorSubject(null);

  constructor() {
    this.initializeCustomElements();

    this.loginService.onLoginChange().subscribe((isLogin) => {
      if (isLogin) {
        return this.onLogin();
      }

      this.onLogout();
    });

    const currentTheme = this.localStorage.getItem('theme');
    if (currentTheme === 'dark') {
      const body = document.body;
      this.renderer.removeClass(body, 'theme-light');
      this.renderer.addClass(body, 'theme-dark');
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt: VersionReadyEvent) => evt.type === 'VERSION_READY'),
          map((evt: VersionReadyEvent) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          })),
          takeUntilDestroyed(),
        )
        .subscribe(() => {
          this.swUpdate
            .activateUpdate()
            .then(() => this.document.location.reload());
        });
    }

    let lang = this.userService.getLang() || getBrowserLang();

    if (!lang || !['en', 'es'].includes(lang.toLowerCase())) {
      lang = 'en';
    }

    this.userService.setLang(lang);
    this.translate.setActiveLang(lang);

    this.gs.set('lang', lang);
  }
  // Funciona correctamente al cerrar pestaña o navegador
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    console.log('👍', 'unload', event);
    if (this.currentUser) {
      this.socket.emit('user_disconnect', { userId: this.currentUser?._id });
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    console.log('👍', 'beforeinstallprompt', e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.window['deferredPrompt'] = e;

    this.addToHomeScreen();
  }

  @HostListener('window:appinstalled', ['$event'])
  installed(e: Event) {
    console.log('👍', 'appinstalled', e);
    this.window['deferredPrompt'] = null;
  }

  @HostListener('window:scroll', ['$event'])
  isInView(e: Event) {
    e.preventDefault();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const scroll: number = window.scrollY || window.pageYOffset;
    const innerHeight: number = window.innerHeight;

    const viewport = {
      top: scroll,
      bottom: scroll + innerHeight,
    };

    this.gs.set('scroll', viewport);
  }

  private addToHomeScreen() {
    if (!this.window['deferredPrompt']) {
      return;
    }
    // Show the prompt

    try {
      this.window['deferredPrompt'].prompt();
      // Wait for the user to respond to the prompt
      this.window['deferredPrompt'].userChoice.then(() => {
        this.window['deferredPrompt'] = null;
      });
    } catch (e) {
      console.debug(e);
    }
  }

  private onLogin() {
    this.isLogin = this.tokenService.get().isValid();

    this.currentUser = this.gs.get('user');

    this.gs.set('isLogin', true);

    this.socket.emit('user_connected', { userId: this.currentUser?._id });

    this.socket.on(
      `user_reset_fingerprints#${this.currentUser?._id}`,
      async () => {
        // Comprobamos si el actual fingerprint esta allowed
        // Sino no lo esta cerramos la sesion
        let fp;
        if (isPlatformBrowser(this.platformId)) {
          fp = await FingerprintJS.load();
        }
        // Get the visitor identifier when you need it.
        const result = await fp?.get?.();
        const fingerprint = result?.visitorId;
        this.userService.checkFingerPrint(fingerprint).subscribe({
          error: () => {
            this.onLogout();
          },
        });
      },
    );

    this.subscribePushNotifications();

    const redirect = this.route.snapshot.queryParamMap.get('redirect');
    if (redirect?.trim?.()) {
      this.router.navigateByUrl(redirect);
    } else {
      this.router.navigate(['/']);
    }
  }

  private onLogout() {
    const user = this.currentUser || this.gs.get('user');
    if (user) {
      this.socket.emit('user_disconnect', { userId: user?._id });
    }

    this.userService.remove();
    this.tokenService.remove();
    this.gs.set('user', null);

    this.isLogin = false;

    this.gs.set('isLogin', this.isLogin);

    this.currentUser = null;

    this.router.navigate(['/']);
  }

  private tryReconnect() {
    console.log('Intentando reconectar...');
    if (
      this.socket &&
      this.socket.ioSocket &&
      !this.socket.ioSocket.connected &&
      !this.socket.ioSocket.connecting
    ) {
      this.socket.connect();
    }
  }

  private async subscribePushNotifications() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.backgroundSync();

    try {
      const serverPublicKey: any = environment.publicKeyWebPush;
      const sub = await this.swPush.requestSubscription({
        serverPublicKey,
      });

      this.notificationService
        .subscribePushNotification(sub)
        .pipe(untilDestroyed(this))
        .subscribe();
    } catch (err) {
      console.log('No se ha podido subscribir a las notificaciones push');
    }
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.currentUser = this.userService.get();
    this.gs.set('user', this.currentUser);
    this.subscribePushNotifications();

    this.gs.subscribe('isLogin', () => {
      this.currentUser = this.userService.get();
      this.gs.set('user', this.currentUser);

      this.subscribePushNotifications();

      if (this.currentUser && this.socket) {
        this.socket.emit('user_connected', {
          userId: this.currentUser?._id,
        });
      }
    });

    this.gs.set(
      'isLogin',
      !!(this.tokenService.get().isValid() && this.currentUser),
    );

    if (isPlatformBrowser(this.platformId)) {
      this.intervalId = setInterval(() => this.tryReconnect(), 2000);
    }

    this.socket.on('connect', () => {
      console.log('Conectado a WS');
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.localStorage.setItem('sockedId', this.socket.ioSocket.id);
      this.gs.set('socketId', this.socket.ioSocket.id);
    });

    this.socket.on('disconnect', () => {
      if (isPlatformBrowser(this.platformId)) {
        this.intervalId = setInterval(() => this.tryReconnect(), 2000);
      }
      console.log('Desconectado de WS');
      this.gs.set('socketId', null);
    });

    this.socket.on('newadvertisment', () => {
      if (
        isPlatformBrowser(this.platformId) &&
        this.currentUser?.role === 'admin'
      ) {
        if (window.Notification && Notification.permission !== 'denied') {
          Notification.requestPermission(() => {
            const n = new Notification('Admefy Administrators notification', {
              body: 'Hay nueva publicidad para revisar!',
              icon: 'https://statics.admefy.com/assets/svg/logo-reducido.svg',
            });
            n.onclick = function (event) {
              event.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(
                `
                https://admin.admefy.com/ads/list`,
                '_blank',
              );
            };
          });
        }

        this.toastr
          .success('Hay nueva publicidad para revisar!')
          .onTap.pipe(untilDestroyed(this))
          .subscribe(() => this.toasterClickedHandler());

        // Reproducimos el sonido de la notificacion
        const sound = new Sound('/assets/sounds/notification.mp3', 100, false);
        sound.start();
        setTimeout(() => {
          sound.remove();
        }, 2000);
      }
    });
    this.socket.on('reconnect_attempt', () => {
      console.log('Intento automatico de reconexión...');
      if (isPlatformBrowser(this.platformId) && !this.intervalId) {
        this.intervalId = setInterval(() => this.tryReconnect(), 2000);
      }
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.spinnerService.registerLoader(
        new Promise((resolve) => {
          this.afterViewInit$
            .pipe(
              takeWhile(() => this.isAlive),
              untilDestroyed(this),
            )
            .subscribe(() => resolve(true));
        }),
      );
      this.spinnerService.load();
      this.addToHomeScreen();
    }

    this.afterViewInit$.next(true);
  }

  private toasterClickedHandler() {
    this.router.navigateByUrl(environment.adminUrl + '/ads/list');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    if (this.currentUser) {
      this.socket.emit('user_disconnect', { userId: this.currentUser?._id });
    }
    this.socket.disconnect(true);
  }

  private backgroundSync() {
    if (!isPlatformBrowser(this.platformId) && 'serviceWorker' in navigator) {
      return;
    }

    navigator?.serviceWorker?.ready
      ?.then?.((registration: any) => {
        registration?.sync?.register?.('notification');
      })
      ?.catch?.(console.error);
  }

  private async initializeCustomElements(): Promise<void> {
    const data = await Promise.all([
      import('./core/components/link/link.component').then((module) => ({
        elementName: 'admefy-link',
        class: 'LinkComponent',
        module,
      })),
    ]);

    for (const element of data) {
      if (!customElements.get(element.elementName)) {
        if (element.module) {
          const component = (element.module as any)[element.class];
          const elementComponent = createCustomElement(component, {
            injector: this.injector,
          });
          customElements.define(element.elementName, elementComponent);
        }
      }
    }
  }
}
